import { useMemo, useEffect, useState } from "react";

import { useParams } from "react-router";
import { isUserExist, showNotification } from "./utils";
import { setUserDataExists } from "./store/wallet/wallet.slice";
import { useDispatch } from "react-redux";
import Header from "./components/Header";
import Footer from "./components/Footer";

import Appstorelogo from "./assets/imgs/appstore.png";
import Playstorelogo from "./assets/imgs/playstore.png";

import Hero from "./components/Hero";
import { useLocation } from "react-router-dom";
// import ContactUs from "./pages/ContactUs";
import { TronLinkAdapter } from "@tronweb3/tronwallet-adapters";
import { WalletConnectAdapter } from "@tronweb3/tronwallet-adapter-walletconnect";
import { contracts } from "./config";
import {
  WalletProvider,
  useWallet,
} from "@tronweb3/tronwallet-adapter-react-hooks";
import {
  WalletActionButton,
  WalletModalProvider,
} from "@tronweb3/tronwallet-adapter-react-ui";
import {
  WalletDisconnectedError,
  WalletError,
  WalletNotFoundError,
} from "@tronweb3/tronwallet-abstract-adapter";

import Adventure from "./assets/scss/Adventure.jpg";
import Ambition from "./assets/scss/Ambition1.jpg";
import Creativity from "./assets/scss/Creativity.jpg";
import Curiosity from "./assets/scss/Curiosity.jpg";
import Diversity from "./assets/scss/Diversity1.jpg";
import Empathy from "./assets/scss/Empathy2.jpg";
import Innovation from "./assets/scss/Innovation1.jpg";
import Prosperity from "./assets/scss/Prosperity.jpg";
import Resilience from "./assets/scss/Resilience1.jpg";
import Serenity from "./assets/scss/Serenity.jpg";
import Sustainability from "./assets/scss/Sustainability.jpg";
import Wisdom from "./assets/scss/Winsdom.jpg";
import axios from "axios";

function Minting() {
  const [isWalletConnected, setIsWalletConnected] = useState(false);
  const [connectedAddress, setConnectedAddress] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  //const [isApproved10LevelRef, setisApproved10LevelRef] = useState(false);

  const [isReferralAllowed, setIsReferralAllowed] = useState(false);
  const [allCollections, setAllCollections] = useState([
    { name: "Leave This Empty", pricePerToken: 0, isApproved: 0 },

    {
      name: "Adventure",
      tribe: "Adventure",
      bgimage: Adventure,
      firstPara:
        "Venture into the vastness of the digital unknown. This NFT is a testament to bravery, urging you to explore where few dare.",
      secondPara:
        "Drawing inspiration from legendary explorers, let the spirit of adventure guide your crypto journey.",
      thirdPara:
        "Step boldly, acquire this NFT, and redefine boundaries in the ever-evolving digital frontier.",
      pricePerToken: 500,
      isApproved: 0,
    },

    {
      name: "Ambition",
      tribe: "Ambition",
      bgimage: Ambition,
      firstPara:
        "Ascend to heights where dreams touch reality. The Tribe of Ambition lights the path for visionaries, echoing the undying spirit of relentless pursuit.",
      secondPara:
        "Each token is a canvas, waiting for your unique story. From historic artistry to modern disruption, creativity knows no bounds.",
      thirdPara:
        "Secure this NFT, ignite your imagination, and weave artistic brilliance into your crypto endeavors.",
      pricePerToken: 500,
      isApproved: 0,
    },

    {
      name: "Creativity",
      tribe: "Creativity",
      bgimage: Creativity,
      firstPara:
        "Journey to a universe where artistic spirits reign. This NFT unlocks a boundless world of innovation, inspired by creators of every era.",
      secondPara:
        "Each token is a canvas, waiting for your unique story. From historic artistry to modern disruption, creativity knows no bounds.",
      thirdPara:
        "Secure this NFT, ignite your imagination, and weave artistic brilliance into your crypto endeavors.",
      pricePerToken: 500,
      isApproved: 0,
    },

    {
      name: "Curiosity",
      tribe: "Curiosity",
      bgimage: Curiosity,
      firstPara:
        "Embark on an odyssey through the vast universe of wonderment. The Tribe of Curiosity beckons those driven by insatiable inquiry, offering a key to realms of knowledge spanning millennia.",
      secondPara:
        "Traverse vast expanses of information, each juncture revealing profound insights. Every discovery here is not an endpoint but a gateway to deeper exploration, from ancient riddles to modern digital enigmas.",
      thirdPara:
        "By aligning with the Tribe of Curiosity, you champion lifelong learning in the crypto cosmos. Embrace this NFT and embark on an unceasing voyage of growth, discovery, and boundless fascination.",
      pricePerToken: 500,
      isApproved: 0,
    },

    {
      name: "Diversity",
      tribe: "Diversity",
      bgimage: Diversity,
      firstPara:
        "Step into a kaleidoscope of human tales, a vibrant confluence of narratives and experiences. The Tribe of Diversity celebrates the myriad facets of humanity, crafting a mosaic of diverse voices.",
      secondPara:
        "Wander through tales that bridge ages, revealing the collective strength derived from individual stories. The magic unfolds when myriad voices harmonize, forming a symphony of shared histories.",
      thirdPara:
        "By joining the Tribe of Diversity, you champion inclusivity in the digital realm. Let this NFT be a testament to a world where every voice echoes with significance, and unity thrives in diversity.",
      pricePerToken: 500,
      isApproved: 0,
    },

    {
      name: "Empathy",
      tribe: "Empathy",
      bgimage: Empathy,
      firstPara:
        "Delve into the tapestry of shared emotions, where heartbeats resonate across time. The Tribe of Empathy champions the ageless virtues of compassion and understanding, weaving tales of heartfelt connections.",
      secondPara:
        "Traverse realms of human emotion, where every sentiment echoes with shared experiences. From age-old tales of compassion to modern narratives of empathy, this tribe celebrates the bonds that unite us.",
      thirdPara:
        "By joining the Tribe of Empathy, you pledge to foster genuine connections in the digital world. Let this NFT stand as a beacon of love, compassion, and a commitment to understanding the heartbeat of the crypto universe.",
      pricePerToken: 500,
      isApproved: 0,
    },

    {
      name: "Innovation",
      tribe: "Innovation",
      bgimage: Innovation,
      firstPara:
        "Dive headfirst into the frontier of tomorrow, where ideas morph into groundbreaking realities. The Tribe of Innovation stands at the crossroads of audacity and ingenuity, heralding the future.",
      secondPara:
        "Navigate the corridors of progress, where innovative marvels await discovery. Each revelation is a testament to the indomitable spirit of human ingenuity, bridging epochs of inventors and today`s disruptors.",
      thirdPara:
        "With the Tribe of Innovation, you are at the epicenter of technological evolution in the crypto domain. This NFT is not just an asset; it is a declaration that the future is yours to shape.",
      pricePerToken: 500,
      isApproved: 0,
    },

    {
      name: "Prosperity",
      tribe: "Prosperity",
      bgimage: Prosperity,
      firstPara:
        "Enter grand corridors of wealth, wisdom and strategy. This NFT unlocks financial secrets held through the ages, tailored for crypto success.",
      secondPara:
        "From age-old wealth mantras to modern techniques, traverse the golden labyrinth of prosperity.",
      thirdPara:
        "Acquire this NFT, and align with principles that have shaped empires. Begin your journey to financial mastery.",
      pricePerToken: 500,
      isApproved: 0,
    },

    {
      name: "Resilience",
      tribe: "Resilience",
      bgimage: Resilience,
      firstPara:
        "Stand unyielding against life is tumultuous waves. The Tribe of Resilience embodies the spirit of perseverance, highlighting tales of undying tenacity across ages.",
      secondPara:
        "Journey through epochs of challenge and triumph, where every adversity is a lesson in human spirit. From ancient warriors to crypto trailblazers, this tribe resonates with stories of undying resolve.",
      thirdPara:
        "Aligning with the Tribe of Resilience equips you to face any crypto tempest. This NFT symbolizes not just survival but thriving against all odds, embodying indomitable spirit.",
      pricePerToken: 500,
      isApproved: 0,
    },

    {
      name: "Serenity",
      tribe: "Serenity",
      bgimage: Serenity,
      firstPara:
        "Retreat to a haven of tranquility amidst digital whirlwinds. This NFT ushers you into a serene space, grounded in age-old mindfulness practices.",
      secondPara:
        "Navigate the crypto realm with calmness and clarity, as ancient teachings guide your every move.",
      thirdPara:
        "Embrace this NFT, and find balance in a world of disruption. Here, peace is not just an ideal; it is an asset.",
      pricePerToken: 500,
      isApproved: 0,
    },

    {
      name: "Sustainability",
      tribe: "Sustainability",
      bgimage: Sustainability,
      firstPara:
        "Tread softly on paths of conscious growth and eco-commitment. This NFT stands as a pledge for planetary well-being and progress.",
      secondPara:
        "Discover the blend of ancient eco-wisdom and innovative sustainability strategies. Every token is a promise of a greener tomorrow.",
      thirdPara:
        "Join this green revolution in crypto. With this NFT, embrace a future where enterprise and ecology coexist harmoniously.",
      pricePerToken: 500,
      isApproved: 0,
    },

    {
      name: "Wisdom",
      tribe: "Wisdom",
      bgimage: Wisdom,
      firstPara:
        "Dive into a realm where ancient echoes and modern thoughts unite. This NFT acts as a bridge, drawing wisdom from past, present, and future.",
      secondPara:
        "Tap into curated teachings of renowned thinkers. Harness this knowledge to navigate the modern crypto landscape with the wisdom of ages.",
      thirdPara:
        "Acquire, align, and let ancient insights guide your digital ventures. This is not just an NFT, it is a beacon of timeless enlightenment.",
      pricePerToken: 500,
      isApproved: 0,
    },
  ]);

  const [selected, setSelected] = useState(allCollections[1].name);
  const handleTribeOnChange = function (e) {
    setSelected(e.target.value);
  };

  useEffect(() => {
    console.log("Updates");
  }, [allCollections]);

  let { referer } = useParams();
  console.log("Referer: " + referer);
  if (referer == null) {
    referer = "";
  }
  //let pricePerToken10Levels = 500;
  let nftContract10Levels = "TG7AKGZD2hbWqaoqRz15rcfyFxHPFD3D4n";
  let usdtContract = "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t";

  const location = useLocation();
  const currentLocation = window.location.host;
  const dispatch = useDispatch();
  const windowInstance = window;
  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setTotalCount(inputValue);
  };
  function onError(e) {
    if (e instanceof WalletNotFoundError) {
      showNotification(e.message, "error");
    } else if (e instanceof WalletDisconnectedError) {
      showNotification(e.message, "error");
    } else showNotification(e.message, "error");
  }

  const adapters = useMemo(function () {
    const tronLinkAdapter = new TronLinkAdapter();
    const walletConnectAdapter = new WalletConnectAdapter({
      network: "Mainnet",
      options: {
        relayUrl: "wss://relay.walletconnect.com",
        // example WC app project ID
        projectId: "a18c7e248b10560477a47c190e778e2a",
        metadata: {
          name: "U House Private sale",
          description: "U House Private sale",
          url: "",
          icons: [""],
        },
      },
      web3ModalConfig: {
        themeMode: "dark",
        themeVariables: {
          "--w3m-z-index": "1000",
        },
      },
    });
    // setwalletConnectAdapter(walletConnectAdapter);
    return [tronLinkAdapter, walletConnectAdapter];
  }, []);
  async function haveUserMinted(nftContractAddress, connectedAddress) {
    try {
      const tron = window.tron;
      const tronWeb = tron?.tronWeb;

      let abi_With_balanceOf = contracts.nftContractABI;

      let contract = await tronWeb?.contract(
        abi_With_balanceOf,
        nftContractAddress
      );
      let result = await contract
        .isReferralAllowed(connectedAddress.toString())
        .call();
      // console.log("Minted: " + result);
      if (nftContract10Levels == nftContractAddress) {
        console.log("10Levels Minted: " + result);
      }
      if (result) {
        setIsReferralAllowed(true);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      // showNotification("Error while fetching nft data", "error");
      console.log(error);
      return false;
    }
  }

  async function mintNft(nftContractAddress, referer, collectionId) {
    try {
      const tron = window.tron;
      const tronWeb1 = tron?.tronWeb;
      if (!(await checkAddress())) {
        showNotification(`Invalid Referral Address.`, "error");
        return;
      }
      if (connectedAddress == "") {
        showNotification("Please connect wallet first", "error");
        return null;
      }
      if (referer == "") {
        referer = connectedAddress;
      }
      //let amount = totalCount * pricePerToken;
      // const amountInSun = tronWeb1.toSun(amount, 6);

      const parameter = [
        {
          type: "address",
          value: referer,
        },
        {
          type: "uint256",
          value: totalCount,
        },
        {
          type: "uint256",
          value: collectionId,
        },
      ];
      console.log(
        "parameters: " +
          JSON.stringify(parameter) +
          "  / connectedAddress: " +
          connectedAddress
      );

      //  let estimate = await tronWeb1.transactionBuilder.estimateEnergy(
      //    nftContractAddress,
      //    "publicMint(address,uint256)",
      //    { feeLimit: 150000000 },
      //    parameter
      //   );
      //
      //   if (estimate.toString().includes("REVERT")) {
      //     console.log(estimate);
      //     return;
      //   }

      const tx = await tronWeb1?.transactionBuilder.triggerSmartContract(
        nftContractAddress,
        "publicMint(address,uint256,uint256)",
        { feeLimit: 1000000000 },
        parameter
      );
      console.log(tx);

      const res = await tronWeb1?.trx.sign(tx.transaction);
      console.log(tx.transaction);
      // const result = await tronWeb.trx.sendRawTransaction(res);
      const senttx = await tronWeb1?.trx.sendRawTransaction(res);
      console.log("senttx: " + senttx);

      if (nftContractAddress == nftContract10Levels) {
        let tempAllCollections = [...allCollections];
        tempAllCollections[collectionId].isApproved = 0;
        setAllCollections(tempAllCollections);
      }
      showNotification(`Nft Minted`, "success");
    } catch (e) {
      console.log(e);
    }
  }
  const approveHandler = async (collectionId) => {
    try {
      let contractAddress = nftContract10Levels;
      const tron = window.tron;
      const tronWeb1 = tron?.tronWeb;
      if (totalCount <= 0) {
        showNotification("Select Quantity", "error");
        return;
      }
      if (connectedAddress == "") {
        showNotification("Please connect wallet first", "error");
        return null;
      }
      if (!(await checkAddress())) {
        showNotification(`Invalid Referral Address.`, "error");
        return;
      }
      let amount = totalCount * allCollections[collectionId].pricePerToken;

      const amountInSun = tronWeb1?.toSun(amount, 6);
      const parameters = [
        {
          type: "address",
          value: contractAddress,
        },
        {
          type: "uint256",
          value: parseInt(amountInSun),
        },
      ];
      console.log(
        "amountInSun: " + amountInSun + " - nftContract: " + contractAddress
      );
      const tx = await tronWeb1?.transactionBuilder.triggerSmartContract(
        usdtContract,
        "approve(address,uint256)",
        { feeLimit: 1000000000, callValue: 0 },
        parameters,
        tronWeb1.address.toHex(connectedAddress)
      );
      console.log("amountInSun", amountInSun);

      const res = await tronWeb1.trx.sign(tx.transaction);

      const result = await tronWeb1.trx.sendRawTransaction(res);
      if (result.result) {
        // alert("Success");
      }
      // if (contractAddress == nftContract10Levels) {
      //   setisApproved10LevelRef(true);
      // }

      let tempAllCollections = [...allCollections];
      tempAllCollections[collectionId].isApproved = 1;

      setAllCollections(tempAllCollections);

      showNotification(`Approved ${amount} USDT`, "success");
    } catch (e) {
      console.log(e);
      showNotification("Something went wrong", "error");
    }
  };
  async function checkAddress() {
    if (referer == null) {
      referer = "";
      return true;
    } else {
      try {
        console.log("About to call API");

        const response = await axios.post(
          "https://api.trongrid.io/wallet/validateaddress",
          {
            address: referer,
          }
        );
        // console.log(response.data.result);
        return response.data.result;
        //showNotification("Invalid Referral Address.", "error");
      } catch (error) {
        console.error("Error validating address:", error);
        // setisRefValid(false);
        return false;
      }
    }
  }
  //console.log(address);
  useEffect(() => {
    // if (!address) return;
    // tronWeb.setAddress(address);

    adapters.forEach((adapter) => {
      adapter.on("readyStateChanged", async () => {
        console.log("readyState: ", adapter.address);
        // console.log("Address: " + address);
      });
      adapter.on("connect", async (address) => {
        //const isUserExists = await isUserExist(address?.toLowerCase());
        if (true) {
          setIsWalletConnected(true);
          setConnectedAddress(address);
          try {
            //dispatch(setUserDataExists(true));

            console.log(address);
            await haveUserMinted(nftContract10Levels, address);
          } catch (error) {
            console.log(error);
          }
        }
      });
      adapter.on("accountsChanged", async (data) => {
        // debugger;
        // dispatch(setUserDataExists(false));
        //const isUserExists = await isUserExist(data?.toLowerCase());
        //  debugger;
        if (true) {
          setIsReferralAllowed(false);
          //dispatch(setUserDataExists(true));
          setIsWalletConnected(true);
          setConnectedAddress(data);
          try {
            console.log(data);
            await haveUserMinted(nftContract10Levels, data);
          } catch (error) {
            console.log(error);
          }
        }
      });

      adapter.on("disconnect", () => {
        try {
          // dispatch(setUserDataExists(false));
          setIsWalletConnected(false);
          setConnectedAddress("");
          setIsReferralAllowed(false);
        } catch (error) {
          console.log(error);
        }
      });

      return () => {
        adapter.removeAllListeners();
      };
    });

    return () => {
      adapters.forEach((adapter) => {
        adapter.removeAllListeners();
      });
    };
  }, [adapters, dispatch]);

  var testDud = currentLocation + "/ref/" + connectedAddress;

  return (
    <div className="hero-page">
      <WalletProvider
        onError={onError}
        autoConnect={true}
        disableAutoConnectOnLoad={true}
        adapters={adapters}
      >
        <Header />

        <div
          className="imagem_nft"
          style={{
            backgroundImage:
              "url(" +
              allCollections.filter((collection) => {
                return collection.name === selected;
              })[0].bgimage +
              ")",
          }}
        >
          <div className="so_telefone">
            To buy an NFT, you need to access the browser within your TronLink
            Wallet. Download the App:<br></br>
            <a
              href="https://apps.apple.com/us/app/tronlink-trx-btt-wallet/id1453530188"
              target="_blank"
            >
              <img src={Appstorelogo} className="bot_tel"></img>
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.tronlinkpro.wallet&hl=es&gl=US"
              target="_blank"
            >
              <img src={Playstorelogo} className="bot_tel"></img>
            </a>
          </div>
          <br></br>
          <div className="box2">
            <h1>
              <span className="sec-branco">
                Mint your{" "}
                {
                  allCollections.filter((collection) => {
                    return collection.name === selected;
                  })[0].tribe
                }
              </span>
              <span className="sec-colorido"> NFT</span>
            </h1>
            <div className="sec-pequeno u-meta ">
              <br></br>
              <h1 className="sec-pequeno">
                Use this Referral Link to earn up to 10% Comission on Platfair
                NFT sales:
              </h1>
              {isReferralAllowed && isWalletConnected && (
                <h1 className="sec-pequeno1">
                  {currentLocation}/ref/{connectedAddress}
                  <button
                    onClick={() => navigator.clipboard.writeText(testDud)}
                    className="buttoncopy"
                  >
                    <svg
                      onClick={() =>
                        showNotification(`Referral Link Copied`, "success")
                      }
                      width="20"
                      height="20"
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        width="13"
                        height="13"
                        x="9"
                        y="9"
                        rx="2"
                        ry="2"
                      ></rect>
                      <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                    </svg>
                  </button>
                </h1>
              )}

              {!isReferralAllowed && isWalletConnected && (
                <h1 className="sec-pequeno1">
                  Mint at least 1 Platfair NFT to unlock your Referral Link.
                </h1>
              )}
              {!isWalletConnected && (
                <h1 className="sec-pequeno1">Connect Your Wallet.</h1>
              )}
            </div>
          </div>

          <div className="wrap wrapWidth flex aic jc">
            <div className="_block flex">
              <div className="block-left-side flex">
                <div className="box">
                  {/*<div className="box2">*/}

                  {/*  cuantos foram vendidos
                <div className="numb-sec flex aic jc">
                  <h3 className="numbs">13 / 10,000 NFTs Sold</h3>
                </div>
                */}

                  <div className="slug">
                    <span className="dot"></span>

                    <h2 className="slug-txt">Select your Tribe:</h2>
                    <span className="dot"></span>
                  </div>
                  <div className="flex aic" style={{ width: "100%" }}>
                    <select
                      className="e-numb cleanbtn tribe-dropdown"
                      value={selected}
                      onChange={(e) => handleTribeOnChange(e)}
                      name="selected-tribe"
                    >
                      {allCollections.map((single, i) => (
                        <>
                          {single.pricePerToken != 0 && (
                            <>
                              <option key={i} value={single.name}>
                                {single.name}
                              </option>
                            </>
                          )}
                        </>
                      ))}
                    </select>
                  </div>
                  <div className="slug">
                    <span className="dot"></span>

                    <h2 className="slug-txt">Select your quantity:</h2>
                    <span className="dot"></span>
                  </div>

                  <div className="mint-price-sec flex aic">
                    {/*<div className="eth-val cleanbtn">10 USDT</div>*/}

                    <input
                      type="number"
                      className="e-numb cleanbtn"
                      placeholder="Quantity"
                      value={totalCount}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="butoes_h">
                    <br></br>
                    <span className="bold_butoes">Premium NFT</span>- Price: 500
                    USDT
                  </div>

                  {/*  Tirar nome do botao  */}
                  <div className="actions flex flex-col">
                    {allCollections.map((single, i) => (
                      <>
                        {single.pricePerToken != 0 &&
                          single.name === selected && (
                            <>
                              {single.isApproved === 1 ? (
                                <button
                                  key={i}
                                  className="btn-mint button cleanbtn"
                                  onClick={() => {
                                    mintNft(nftContract10Levels, referer, i);
                                  }}
                                >
                                  Step 2 of 2 → Mint NFT ({single.name})
                                </button>
                              ) : (
                                <button
                                  className="btn-mint button cleanbtn"
                                  onClick={() => {
                                    approveHandler(i);
                                  }}
                                >
                                  Step 1 of 2 → Approve Your Wallet (
                                  {single.name})
                                </button>
                              )}
                            </>
                          )}
                      </>
                    ))}
                  </div>
                </div>
                {/*</div>*/}
              </div>

              <div className="block-right-side flex">
                <p className="texto_tribu">
                  <span className="titulo_texto_tribu">
                    {" "}
                    Tribe of{" "}
                    {
                      allCollections.filter((collection) => {
                        return collection.name === selected;
                      })[0].tribe
                    }
                  </span>

                  <span>
                    {
                      allCollections.filter((collection) => {
                        return collection.name === selected;
                      })[0].firstPara
                    }
                  </span>

                  <span>
                    {
                      allCollections.filter((collection) => {
                        return collection.name === selected;
                      })[0].secondPara
                    }
                  </span>

                  <span>
                    {
                      allCollections.filter((collection) => {
                        return collection.name === selected;
                      })[0].thirdPara
                    }
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div>
          <Footer />
        </div>
      </WalletProvider>
    </div>
  );
}

{
  /* function ConnectWalletComponent() {
  return (
    <WalletActionButton className="btn btn-primary" style={{ width: "100%" }}>
      Connect Wallet
    </WalletActionButton>
  );
} */
}

export default Minting;
